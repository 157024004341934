<template>
	<b-row class="match-height" v-if="selectedPayment && getSelectedContract">
		<b-col sm="12">
			<b-card class="mb-1">
				<b-card-body class="d-flex justify-content-between align-items-center m-0 p-0">
					<b-card-title class="my-auto font-weight-bolder text-center text-md-left p-0">
						Detalhes da Parcela
					</b-card-title>
					<div
						style="cursor: pointer"
						@click="$router.go(-1)"
						class="d-flex align-items-center"
					>
						<feather-icon icon="ChevronLeftIcon" size="25" />Voltar
					</div>
				</b-card-body>
			</b-card>
		</b-col>

		<b-col sm="12" md="7">
			<b-card>
				<b-card-text class="my-auto font-weight-bolder text-center" id="contract-info">
					{{ getSelectedContract.empreendimento_nome }} <br />
					Quadra {{ getSelectedContract.quadra }} - Lote {{ getSelectedContract.lote }}
				</b-card-text>

				<b-card-header class="text-center">
					<b-card-title class="mx-auto" id="parcela-descricao">
						Parcela {{ selectedPayment.descricao }} {{ selectedPayment.parcela }}
					</b-card-title>
				</b-card-header>

				<b-card-body>
					<div
						class="rounded-circle mx-auto d-flex flex-wrap justify-content-center align-items-center"
						v-bind:class="{
							border_pago: selectedPayment.situacao_desc == 'Pago',
							border_vencido: selectedPayment.situacao_desc == 'Vencido',
							border_vencer: selectedPayment.situacao_desc == 'A Vencer',
							border_reajustar: selectedPayment.situacao_desc == 'A Reajustar',
						}"
					>
						<!-- <b-badge
              style="font-size: 15px"
              class="float-top position-absolute mb-5"
              id="parcela-situacao"
              :variant="variantPayment(selectedPayment.situacao_desc)"
            >
              {{ selectedPayment.situacao_desc }}
            </b-badge> -->

						<b-card-title class="m-auto" style="font-size: 25px" id="parcela-valor">
							<template v-if="selectedPayment.valor_pago">
								{{ formatPriceTable(selectedPayment.valor_pago) }}
							</template>
							<template v-else>
								{{ formatPriceTable(selectedPayment.valor_original) }}
							</template>
						</b-card-title>
					</div>

					<b-button
						v-if="selectedPayment.boleto_link"
						:href="selectedPayment.boleto_link"
						target="_blank"
						variant="primary"
						class="d-flex d-md-none align-items-center justify-content-center col-12 col-md-2 mt-2"
					>
						<feather-icon size="20" icon="FileTextIcon" />
						<span class="font-medium-2" style="margin-left: 10px">Gerar Boleto</span>
					</b-button>
					<div class="d-flex justify-content-center mt-2">
						<b-button
							@click="copyUPC()"
							v-if="selectedPayment.ipte"
							variant="success"
							class="px-0 d-md-none"
							block
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21"
								height="21"
								fill="currentColor"
								class="bi bi-upc"
								viewBox="0 0 16 16"
							>
								<path
									d="M3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"
								/>
							</svg>
							Copiar Código de Barras
						</b-button>
					</div>
					<div class="text-center d-flex mt-2 py-1 border-sm-y">
						<div class="col-6" id="parcela-vencimento">
							<p class="m-0 info-title">Vencimento</p>
							<p class="font-weight-bolder m-0 info-text">
								{{ formatDate(selectedPayment.data_vencimento) }}
							</p>
						</div>

						<div class="col-6 border-md-left" id="parcela-pagamento">
							<p class="m-0 info-title">Pagamento</p>
							<template v-if="selectedPayment.data_pagamento">
								<p class="font-weight-bolder m-0 info-text">
									{{ formatDate(selectedPayment.data_pagamento) }}
								</p>
							</template>
							<template v-else>
								<p class="font-weight-bolder m-0 info-text">Não pago</p>
							</template>
						</div>
					</div>

					<div class="d-block d-md-none mt-2">
						<div class="detalhes-pagamento-item d-flex justify-content-between">
							<div>
								<feather-icon
									icon="CircleIcon"
									size="13"
									class="mr-50 bg-primary bg-lighten-1 text-primary text-lighten-1 rounded-circle"
								/>
								<span class="font-weight-bolder">Valor Original</span>
							</div>
							<span v-if="selectedPayment.valor_original">{{
								formatPriceTable(selectedPayment.valor_original)
							}}</span>
							<span v-else>R$ 0,00</span>
						</div>

						<div class="detalhes-pagamento-item d-flex justify-content-between">
							<div>
								<feather-icon
									icon="CircleIcon"
									size="13"
									class="mr-50 bg-info bg-darken-2 text-info text-darken-2 rounded-circle"
								/>
								<span class="font-weight-bolder">Valor Aberto</span>
							</div>
							<span v-if="selectedPayment.valor_aberto">{{
								formatPriceTable(selectedPayment.valor_aberto)
							}}</span>
							<span v-else>R$ 0,00</span>
						</div>

						<div class="detalhes-pagamento-item d-flex justify-content-between">
							<div>
								<feather-icon
									icon="CircleIcon"
									size="13"
									class="mr-50 bg-warning text-warning rounded-circle"
								/>
								<span class="font-weight-bolder">Descontos</span>
							</div>
							<span v-if="selectedPayment.valor_descontos">{{
								formatPriceTable(selectedPayment.valor_descontos)
							}}</span>
							<span v-else>R$ 0,00</span>
						</div>

						<div class="detalhes-pagamento-item d-flex justify-content-between">
							<div>
								<feather-icon
									icon="CircleIcon"
									size="13"
									class="mr-50 bg-danger text-danger rounded-circle"
								/>
								<span class="font-weight-bolder">Juros/Multa</span>
							</div>
							<span v-if="selectedPayment.valor_juros_multa">{{
								formatPriceTable(selectedPayment.valor_juros_multa)
							}}</span>
							<span v-else>R$ 0,00</span>
						</div>

						<div class="detalhes-pagamento-item d-flex justify-content-between">
							<div>
								<feather-icon
									icon="CircleIcon"
									size="13"
									class="mr-50 bg-success text-success rounded-circle"
								/>
								<span class="font-weight-bolder">Valor Pago</span>
							</div>
							<span v-if="selectedPayment.valor_pago">{{
								formatPriceTable(selectedPayment.valor_pago)
							}}</span>
							<span v-else>R$ 0,00</span>
						</div>
					</div>
				</b-card-body>
			</b-card>
		</b-col>

		<b-col class="d-none d-md-block" md="5">
			<b-card>
				<b-card-body class="py-0">
					<div
						class="detalhes-pagamento-item d-flex justify-content-between"
						id="parcela-valor-original"
					>
						<div>
							<feather-icon
								icon="CircleIcon"
								size="13"
								class="mr-50 bg-primary bg-lighten-1 text-primary text-lighten-1 rounded-circle"
							/>
							<span class="font-weight-bolder">Valor Original</span>
						</div>
						<span v-if="selectedPayment.valor_original">{{
							formatPriceTable(selectedPayment.valor_original)
						}}</span>
						<span v-else>R$ 0,00</span>
					</div>

					<div
						class="detalhes-pagamento-item d-flex justify-content-between"
						id="parcela-valor-aberto"
					>
						<div>
							<feather-icon
								icon="CircleIcon"
								size="13"
								class="mr-50 bg-info bg-darken-2 text-info text-darken-2 rounded-circle"
							/>
							<span class="font-weight-bolder">Valor Aberto</span>
						</div>
						<span v-if="selectedPayment.valor_aberto">{{
							formatPriceTable(selectedPayment.valor_aberto)
						}}</span>
						<span v-else>R$ 0,00</span>
					</div>

					<div
						class="detalhes-pagamento-item d-flex justify-content-between"
						id="parcela-valor-descontos"
					>
						<div>
							<feather-icon
								icon="CircleIcon"
								size="13"
								class="mr-50 bg-warning text-warning rounded-circle"
							/>
							<span class="font-weight-bolder">Descontos</span>
						</div>
						<span v-if="selectedPayment.valor_descontos">{{
							formatPriceTable(selectedPayment.valor_descontos)
						}}</span>
						<span v-else>R$ 0,00</span>
					</div>

					<div
						class="detalhes-pagamento-item d-flex justify-content-between"
						id="parcela-valor-multa"
					>
						<div>
							<feather-icon
								icon="CircleIcon"
								size="13"
								class="mr-50 bg-danger text-danger rounded-circle"
							/>
							<span class="font-weight-bolder">Juros/Multa</span>
						</div>
						<span v-if="selectedPayment.valor_juros_multa">{{
							formatPriceTable(selectedPayment.valor_juros_multa)
						}}</span>
						<span v-else>R$ 0,00</span>
					</div>

					<div
						class="detalhes-pagamento-item d-flex justify-content-between"
						id="parcela-valor-pago"
					>
						<div>
							<feather-icon
								icon="CircleIcon"
								size="13"
								class="mr-50 bg-success text-success rounded-circle"
							/>
							<span class="font-weight-bolder">Valor Pago</span>
						</div>
						<span v-if="selectedPayment.valor_pago">{{
							formatPriceTable(selectedPayment.valor_pago)
						}}</span>
						<span v-else>R$ 0,00</span>
					</div>
					<b-button
						v-if="selectedPayment.boleto_link"
						@click="
							$router.push({
								name: 'document-viewer',
								params: {
									data: selectedPayment.boleto_link,
									documentName: 'Boleto ' + selectedPayment.data_vencimento,
								},
							})
						"
						target="_blank"
						variant="primary"
						block
						id="parcela-button-boleto"
						class="d-flex align-items-center justify-content-center mt-3"
					>
						<feather-icon size="20" icon="FileTextIcon" />
						<span class="font-medium-2" style="margin-left: 10px">Gerar Boleto</span>
					</b-button>

					<b-button
						v-if="selectedPayment.ipte"
						@click="copyUPC()"
						variant="success"
						class="px-0"
						block
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="21"
							height="21"
							fill="currentColor"
							class="bi bi-upc"
							viewBox="0 0 16 16"
						>
							<path
								d="M3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"
							/>
						</svg>
						Copiar Código de Barras
					</b-button>
				</b-card-body>
			</b-card>
		</b-col>
	</b-row>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BCardTitle,
	BCardBody,
	BCardText,
	BRow,
	BCol,
	BButton,
} from 'bootstrap-vue'
import store from '@/store'
import formatValue from '@/utils/formatValue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
	components: {
		BButton,
		BCard,
		BCardHeader,
		BRow,
		BCol,
		BCardTitle,
		BCardBody,
		BCardText,
	},
	mixins: [formatValue],
	methods: {
		variantPayment: function(descricao) {
			if (descricao == 'Pago') {
				return 'light-success'
			} else if (descricao == 'A Vencer') {
				return 'light-info'
			} else {
				return 'light-danger'
			}
		},

		copyUPC() {
			navigator.clipboard.writeText(this.selectedPayment.ipte)
			this.$toast({
				component: ToastificationContent,
				props: {
					title: 'Código de barras copiado!',
					icon: 'CheckIcon',
					variant: 'success',
				},
			})
		},
	},

	computed: {
		getSelectedContract() {
			return store.getters['contracts/getSelectedContract']
		},

		selectedPayment() {
			return store.getters['contracts/getSelectedPayment']
		},
	},
}
</script>

<style scoped>
.border_pago {
	border: 7px solid rgb(97, 189, 97);
	width: 190px;
	height: 190px;
}

.border_vencido {
	border: 7px solid rgba(240, 63, 63, 0.788);
	width: 190px;
	height: 190px;
}

.border_vencer {
	border: 7px solid rgb(20, 187, 243);
	width: 190px;
	height: 190px;
}

.border_reajustar {
	border: 7px solid #e59042;
	width: 190px;
	height: 190px;
}

.info-title,
.info-text {
	white-space: nowrap;
	justify-content: center;
	align-items: center;
}

@media (min-width: 768px) {
	.border-md-left {
		border-left: 1px solid rgba(204, 204, 204, 0.37);
	}

	.info-title {
		font-size: 1.1rem;
	}

	.info-text {
		font-size: 1.3rem;
	}
}

/* media query small*/
@media (max-width: 767.9px) {
	.border-sm-y {
		border-top: 1px solid rgba(204, 204, 204, 0.37);
		border-bottom: 1px solid rgba(204, 204, 204, 0.37);
	}
	.info-title {
		font-size: 1rem;
	}

	.info-text {
		font-size: 1.2rem;
	}
}

@media (max-width: 325px) {
	.info-title {
		font-size: 0.7rem;
	}

	.info-text {
		font-size: 0.9rem;
	}
}
</style>
